import { NavBar } from '../navBar/NavBar';
import { HeroBanner } from '../heroBanner/HeroBanner';
import { SocialBar } from '../socialBar/SocialBar';
import { MarkBar } from '../markBar/MarkBar';

export const App = () => {
  return (
    <div className="relative p-4 md:p-12 h-full">
      <div className="relative flex flex-col border border-1 border-black bg-gradient-to-br from-heroFrom via-heroVia to-heroTo shadow-md h-full">
        <header className="shrink p-8">
          <div className="grid grid-cols-12">
            <a className="col-start-1 col-end-3" href="/" rel="nofollow">
              <img className="w-24" src="/logo-smsalvador.png" alt="Salvador Saldaña" />
            </a>
            <div className="col-end-13 col-span-10 self-center md:self-start">
              <NavBar className="float-right" />
            </div>
          </div>
        </header>
        <main className="grow p-8">
          <HeroBanner className="" />
        </main>
        <footer className="absolute left-0 right-0 bottom-0">
          <div className="grid grid-cols-12">
            <SocialBar className="col-start-1 col-end-2 self-end" />
            <MarkBar className="col-end-13 col-span-1" />
          </div>
        </footer>
        <img className="absolute w-72 rotate-180 right-0 bottom-0 opacity-5" src="/corner.png" alt="" />
      </div>
    </div>
  );
};
