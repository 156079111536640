export const MarkBar = (props: { className?: string }) => {
  return (
    <div className={`${props.className ?? ''} text-center`}>
      <span
        className="inline-block transform rotate-180 text-center font-bold text-sm text-white uppercase mr-4 md:mr-0 mb-2 md:mb-12 opacity-20"
        style={{ writingMode: 'vertical-rl' }}
      >
        Salvador &nbsp; Saldaña &nbsp; Maldonado
      </span>
    </div>
  );
};
