import { TagCloud, TagCloudOptions } from '@frank-mayer/react-tag-cloud';

export const HeroBanner = (props: { className?: string }) => {
  return (
    <div className={`${props.className ?? ''} flex flex-col lg:flex-row`}>
      <div className="grow flex flex-col self-center text-center text-white">
        <h1 className="text-xl font-bold">Senior Typescript / Javascript / Python developer</h1>
        <h2 className="text-lg">Software Architect</h2>
      </div>
      <div className="shrink self-center text-white pt-2 lg:pt-0 px-8">
        <TagCloud
          options={(w: Window & typeof globalThis): TagCloudOptions => {
            return {
              radius: (w.innerWidth > 500 ? 400 : 280) / 2,
            };
          }}
        >
          {[
            'Linux',
            'Windows',
            'BSD',
            'MacOS',
            'RTOS',
            'RISC OS',
            'Python',
            'Typescript',
            'Javascript',
            'Golang',
            'SQL',
            'Shell Script',
            'HTML5',
            'CSS3',
            'SASS/LESS',
            'Django',
            'Flask',
            'Tornado',
            'CherryPy',
            'MicroPython',
            'AssemblyScript',
            'NodeJS',
            'WebPack',
            'Parcel',
            'React',
            'VUE',
            'Angular',
            'Express',
            'NestJS',
            'SocketIO',
            'PHP',
            'Laravel',
            'CakePHP',
            'CodeIgniter',
            'Grav',
            'Wordpress',
            'PostgreSQL',
            'MySQL',
            'MongoDB',
            'MSSQL',
            'SQLite',
            'Redis',
            'Docker',
            'GraphQL',
            'Packer',
            'GIT',
            'SSH',
            'Cypress',
            'Apache',
            'Nginx',
            'Elastic Search',
            'Azure',
            'AWS',
            'Google Cloud',
          ]}
        </TagCloud>
      </div>
    </div>
  );
};
