import { ReactSocialMediaIcons } from 'react-social-media-icons';

export const SocialBar = (props: { className?: string }) => {
  const itemClasses = 'opacity-60 hover:opacity-100 ease-in duration-100';

  return (
    <div className={`${props.className ?? ''} flex flex-col items-center gap-4 pl-4 md:pl-0 mb-2 md:mb-12`}>
      <div className={itemClasses}>
        <ReactSocialMediaIcons
          borderColor="transparent"
          icon="linkedin"
          iconColor="#FFFFFF"
          backgroundColor="transparent"
          url="https://www.linkedin.com/in/smsalvador"
          size="32"
        />
      </div>
      <div className={itemClasses}>
        <ReactSocialMediaIcons
          borderColor="transparent"
          icon="facebook"
          iconColor="#FFFFFF"
          backgroundColor="transparent"
          url="https://www.facebook.com/sm.salvador"
          size="32"
        />
      </div>
      <div className={itemClasses}>
        <ReactSocialMediaIcons
          borderColor="transparent"
          icon="instagram"
          iconColor="#FFFFFF"
          backgroundColor="transparent"
          url="https://www.instagram.com/sm_salvador"
          size="32"
        />
      </div>
    </div>
  );
};
