/* eslint-disable jsx-a11y/anchor-is-valid */

export const NavBar = (props: { className?: string }) => {
  const itemClasses = 'inline-block opacity-60 hover:opacity-100 ease-in duration-100';

  return (
    <nav className={`${props.className ?? ''} flex flex-row gap-4 text-sm text-white font-bold uppercase`}>
      <a className={`${itemClasses} cursor-not-allowed`} href="#">
        Projects
      </a>
      <a className={itemClasses} href="mailto:contact@smsalvador.com">
        Contact
      </a>
    </nav>
  );
};
